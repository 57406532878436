<template>
    <v-card class="white border-boxGlobal">
        <block v-if="loading"></block>
        <v-card-title>
            <h3 style="margin: 1rem 0;">
                Gestión de minimos y maximos 
            </h3>
        </v-card-title>
        
        <VersatileFilter 
            class="mx-3"
            :columnsSearch="columsConfig" 
            :columnfilterEndpoint="$API.inventoryReports.getInventoryFilterReportColumn"
            :search="search" 
            :key="Reloadfilter" 
            :useCache="true"
            :oneDateFilter="true"
            @emptyFilter="getEarningDetail" 
            @filtered="filterHandler" 
            @updateCache="updateCache" 
        >
            <DxDataGrid 
                class="isScrolledByGeneralFilter" 
                :data-source="reportsData" 
                :show-borders="true"
                :scrolling="{ useNative: true }"
                :column-auto-width="true"
                @exporting="onExporting" 
                @selectionChanged="selectionChanged" 
                :paging="{ enabled: false }" 
                style="min-height: 600px; max-height:600px"
            >
                <DxExport :enabled="true" />
                <DxColumn cell-template="updateValues" />
                <DxColumn data-field="ID_Item" caption="ID Producto" />
                <DxColumn data-field="PrincipalBarCode" caption="Código Alterno Principal" />
                <DxColumn data-field="ItemName" caption="Nombre Producto" />
                <DxColumn data-field="Lote" caption="Lote" />
                <DxColumn data-field="LastPurchaseDate" caption="Última Fecha de compra" />
                <DxColumn data-field="LastPurchasePrice" caption="Último Costo de compra" />
                <DxColumn data-field="AverageCostFormatted" caption="Costo promedio" />
                <DxColumn data-field="Providers" caption="Proveedores" />
                <DxColumn data-field="WarehouseName" caption="Almacén" />
                <DxColumn data-field="ItemMeasureUnit" caption="Unidad" />
                <DxColumn data-field="ItemMinimum" caption="Minimo" />
                <DxColumn data-field="ItemMaximum" caption="Maximo" />
                <DxColumn data-field="TotalStock" :width="100" caption="Disponible" />
                <template #updateValues="{ data }">
                    <a href="#" class="mx-1" @click.prevent.stop="handleClickSettings(data.data)">
                        <v-icon>mdi-cog</v-icon>
                    </a>
                </template>
                
                <DxScrolling mode="virtual"/>
                <DxLoadPanel :enabled="true"/>
            </DxDataGrid>
        </VersatileFilter>

        <v-dialog v-model="showReport" max-width="80%" scrollable>
            <v-card class="border" style="padding: 1rem; overflow-y: auto;" >
                <v-btn @click="handleCloseForm" elevation="0" color="red" class="close-btn align-self-end">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <div v-if="selectedReport">
                    <v-row style="margin-top: 0.5rem">
                        <v-col cols="12" md="4">
                            <date-picker label="Fecha Ajuste" @dateSelected="changueDt" v-model="form.date"
                                :value="form.date" /> 
                        </v-col>
                    </v-row>
                    <v-row style="padding: 1rem 0;">
                        <v-col cols="12">
                            <h3>Detalle del producto</h3>
                        </v-col>
                    </v-row>
                    <v-row style="padding: 1rem 0;">
                        <v-col cols="12" sm="6" md="4" xl="4" style="margin: 0.5rem 0;">
                            <span>
                                <strong>ID Producto: </strong>
                                {{ selectedReport.ID_Item }}
                            </span>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" xl="4" style="margin: 0.5rem 0;">
                            <span>
                                <strong>Nombre: </strong>
                                {{ selectedReport.ItemName }}
                            </span>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" xl="4" style="margin: 0.5rem 0;">
                            <span>
                                <strong>Almacén: </strong>
                                {{ selectedItemWarehouseName }}
                            </span>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" xl="4" style="margin: 0.5rem 0;">
                            <span>
                                <strong>Lote: </strong>
                                {{ selectedReport.Lote ? selectedReport.Lote : "Por defecto" }}
                            </span>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" xl="4" style="margin: 0.5rem 0;">
                            <span>
                                <strong>Unidad: </strong>
                                {{ selectedReport.ItemMeasureUnit }}
                            </span>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" xl="4" style="margin: 0.5rem 0;">
                            <span>
                                <strong>Último precio ingresado: </strong>
                                {{
                                    selectedReport.LastPrice
                                }}
                            </span>
                        </v-col>
                    </v-row>
                
                    <v-row>
                        <v-col cols="12" sm="6" md="4" xl="3">
                            <h4 style="padding: 8px 0;">Minimos del producto:</h4>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" xl="3">
                            <v-text-field v-model="form.minimum" label="Cantidad" outlined dense type="number" />
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12" sm="6" md="4" xl="3">
                            <h4 style="padding: 8px 0;">Maximos del producto:</h4>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" xl="3">
                            <v-text-field v-model="form.maximum" label="Cantidad" outlined dense type="number" />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col col="12" md="4" xl="4">
                            <v-btn @click="showComments = !showComments" :class="form.comment ? 'btnclean' : 'btnblack'">
                                Comentarios
                            </v-btn>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col cols="12" md="6">
                            <h2>
                                {{ "Existencias Actuales: " + stockToShow }}
                            </h2>
                        </v-col>
                        <v-col cols="12" md="6">
                            <h2>
                                {{ "Existencias Nuevas: " + totalStock }}
                            </h2>
                        </v-col>
                    </v-row> -->
                </div>
                <v-card-actions class="mt-3" style="padding: 1rem 0;">
                    <v-btn @click="handleCloseForm" class="btndelete">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="btnsave" @click="updateInventoryItem">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showComments" max-width="80%">
            <v-card>
                <div style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: flex-end;
          ">
                    <v-btn small class="btndelete" @click.prevent="showComments = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-col cols="12" md="12" style="">
                    <quill-editor v-model="form.comment"></quill-editor>
                </v-col>
            </v-card>
        </v-dialog>

        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
    </v-card>
</template>

<script>
    import { DxDataGrid, DxColumn, DxExport,  DxScrolling, DxLoadPanel, } from "devextreme-vue/data-grid";
    import { printToExcel } from "@/helpers/printToexcel";
    import VersatileFilter from "@/components/VersatileFilter";
    import Block from "@/components/Block";
    import DatePicker from "@/components/DatePicker";
    import Alerts from "@/components/Alerts";

    export default {
        name: "InventoyMinimumAndMaximum",
        components: {
            Block,
            DxDataGrid,
            DxColumn,
            DxExport,
            DxScrolling,
            DxLoadPanel,
            DatePicker,
            Alerts,
            VersatileFilter,
        },
        props: ["win", "datafilter"],
        data() {
            return {
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                loading: false,
                panel: 0,
                reportsData: [],
                start: null,
                end: null,
                summary: {
                    totalUnitSales: 0,
                    totalCostSales: 0,
                },
                search: {},
                showFilter: false,
                filterParams: {
                    ID_Item: null,
                    ItemName: null,
                    ItemLote: null,
                    providersNames: null,
                    WarehouseName: null,
                    add: null,
                },
                Reloadfilter: 0,
                columsConfig: [
                    {
                        name: "Warehouse",
                        title: "Almacenes",
                        value: "",
                        type: "select",
                        options: [],
                    },
                    {
                        name: "family",
                        title: "Familia",
                        value: "",
                        type: "select",
                        options: [],
                    },
                    {
                        name: "category",
                        title: "Categoría",
                        value: "",
                        parent: 'family',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "subcategory",
                        title: "Subcategoría",
                        value: "",
                        parent: 'category',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "Lote",
                        title: "Lote",
                        value: "",
                        type: "select",
                        options: [],
                    },
                ],
                cacheData: [],
                selectedReport: null,
                form: {},
                showComments: false,
                showReport: false,
                warehouses: [],
            
            };
        },
        computed: {
            selectedItemWarehouseName() {
                if ( this.form.warehouse && !this.selectedReport.WarehouseName ) {
                    // console.log( 'this.form.warehouse', this.form.warehouse, this.selectedReport.WarehouseName )
                    return this.datafilter.Warehouses.find( current => {
                        const { value: ID_Warehouse } = current;
                        return ID_Warehouse === this.form.warehouse;
                    } ).name;
                } else {
                    return this.selectedReport.WarehouseName
                } 
            },
            totalStock() {
                if (this.selectedReport.TotalStock && this.form.quantity && !this.form.add) {
                    return this.selectedReport.TotalStock - this.form.quantity;
                } else if (this.selectedReport.TotalStock && this.form.quantity && this.form.add) {
                    return this.selectedReport.TotalStock + +this.form.quantity;
                } else if (this.selectedReport.TotalStock) {
                    return this.selectedReport.TotalStock;
                }
                else if((this.selectedReport.TotalStock == null || this.selectedReport.TotalStock == undefined || this.selectedReport.TotalStock == 0 ) && this.form.quantity  ) 
                {
                    return this.form.add ? this.form.quantity : -this.form.quantity;
                } 
                return 0;
            },
            getsetLote: {
                get () {        
                
                    return this.selectedReport.Lote
                
                },
                set (val) {
                    // alert(val)
                    if (val=="") {
                    this.form.lote =  this.selectedReport.Lote
                    } else {
                    this.form.lote = val
                    }
                    
                }
            },
            getsetAverageCost: {
            get () {
            
                return this.selectedReport.AverageCost
            
            },
            set (val) {
            
            
                this.form.price = val
                
            }
            },
            getsetWarehouse: {
            
            get () {        
            
                return this.selectedReport.Warehouse
            
            },
            set (val) {
                // alert(val)
                if (val=="") {
                this.form.warehouse = this.selectedReport.Lote
                } else {
                this.form.warehouse = val
                }
                
            }
            },
            stockToShow() {
                return this.selectedReport.TotalStock ? this.selectedReport.TotalStock : 0;
            },
        },
        mounted() {
            this.assignParams()
        
        },
        methods: {
            verifyR() {
                if (!this.selectedReport) {
                    this.showAlert("warning", "Ajuste de inventario", "Seleccione un producto");
                    return;
                }
                this.showReport = true;
            },
            closeAlert() {
                this.alert.show = false;

                if (this.alert.options == "reload") {
                    this.getEarningDetail();
                }
                else if (this.alert.options == "reloadwithalert") {
                    console.log('recargando');
                    this.reloadDatawithParams();
                
                }
            },
            acceptAlert() {
                this.alert.show = false;
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            reloadDatawithParams(){
                this.cacheData.fromAdjustment = true;
                this.$API.inventoryReports.getInventoryFilterReportColumn(this.cacheData).then(response => {
                    this.reportsData = response;
                })
            },
            handleClickSettings(data) {
                this.selectedReport = data; 
                this.form.ID_Warehouse = data.Warehouse; 
                this.form.ID_Branch = data.ID_Branch; 
                this.form.ID_Business = data.ID_Business; 
                this.form.ID_Item = data.ID_Item; 
                this.form.ID_ItemBatch = data.Lote;
                this.form.minimum = data.ItemMinimum;
                this.form.maximum = data.ItemMaximum;
                //this.form.add = true;
                this.showReport = true;

                console.log(this.form);
            },
            handleCloseForm() {
                this.form.ID_Warehouse = null; 
                this.form.ID_Branch = null; 
                this.form.ID_Business = null; 
                this.form.ID_Item = null; 
                this.form.ID_ItemBatch = null;
                this.form.minimum = null;
                this.form.maximum = null;
                this.showReport = false;
            },
            updateInventoryItem() {
                this.showReport = false;
                this.loading = true;
                if(this.form.ID_Warehouse == null || this.form.ID_Warehouse == '' || !this.form.ID_Warehouse){
                    return this.showAlert("warning", "Ajuste de inventario", "Debe seleccionar un almacén.");
                }
                if(this.form.ID_Item == null || this.form.ID_Item == '' || !this.form.ID_Item){
                    return this.showAlert("warning", "Ajuste de inventario", "Debe seleccionar un producto.");
                }
                if((this.form.minimum === null || this.form.minimum === '' || this.form.minimum === undefined) || (this.form.maximum === null || this.form.maximum === '' || this.form.maximum === undefined)){
                    return this.showAlert("warning", "Ajuste de inventario", "Debe ingresar un valor numerico en los minimos y maximos.");
                }
                this.$API.inventoryReports.updateInventoryMinMax(this.form)
                .then((res) => {
                        this.showReport = false;
                        this.loading = false;
                        this.showAlert("success", "Ajuste de inventario", "Se actualizaron los minimos y maximos de inventario con exito.");
                        console.log(res);
                        this.handleCloseForm();
                        this.selectedReport = null;
                        this.reloadDatawithParams();
                    })
                    .catch((err) => {
                        this.loading = false;
                        this.showAlert("warning", "Ajuste de inventario", "Ocurrio un error al actualizar los minimos y maximos.");
                        this.handleCloseForm();
                        this.selectedReport = null;
                        console.log(err);
                    });
            },
            updateCache(data) {
                this.cacheData = data;
            },
            selectionChanged(e) {
                var selectedRows = e.selectedRowsData;
                if (selectedRows.length > 0) {
                    this.selectedReport = selectedRows[0];
                }
            },

            getEarningDetail() {
                this.loading = true;
                this.$API.inventoryReports
                    .getInventoryReport()
                    .then((response) => {
                        this.loading = false;
                        this.reportsData = response;
                        this.selectedReport = null;
                    })
                    .catch((error) => {
                        this.loading = false;
                        console.log(error);
                    });
            },
            onExporting(e) {
                printToExcel(e, "Reporte Fisico");
            },
            filterHandler(response) {
                this.reportsData = response;
            },
            cleanFilter() {
                this.filterParams = {
                    ID_Item: null,
                    ItemName: null,
                    ItemLote: null,
                    providersNames: null,
                    WarehouseName: null,
                };
                this.getEarningDetail();
            },
            getFilterEarningDetail() {
                this.loading = true;
                this.$API.inventoryReports
                    .getInventoryFilterReport(this.filterParams)
                    .then((response) => {
                        this.loading = false;
                        this.reportsData = response;
                    })
                    .catch((error) => {
                        this.loading = false;
                        console.log(error);
                    });
            },
            changueDt(date) {
                this.form.date = date;
            },
            assignParams() {
                if (this.datafilter) {
                    this.columsConfig[0].options = this.datafilter.Warehouses;
                    this.columsConfig[1].options = this.datafilter.Families;
                    this.columsConfig[2].options = this.datafilter.Categories;
                    this.columsConfig[3].options = this.datafilter.SubCategories;
                    this.columsConfig[4].options = this.datafilter.batches;
                    this.Reloadfilter += 1;
                }
            },
        }
    };
</script>

<style scoped>
    .btnblack {
        background-color: rgba(60, 60, 60, 1) !important;
        color: white !important;
    }

    .close-btn {
        color: white !important;
        position: relative !important;
        float: right !important;
    }

    .border {
    border-radius: 15px !important;
    }
</style>
