<template>
    <v-card class="white border-boxGlobal">
        <v-card-title> Ajuste de Inventario </v-card-title>

        <v-row class="mx-1">
            <!--   <v-col cols="12" class="d-flex justify-end">
              
                <v-btn class="btnclean" @click="showFilter = true" >Filtros</v-btn>
            </v-col> -->
        </v-row>
        <block v-if="loading"></block>
        
        <VersatileFilter :columnsSearch="columsConfig" :search="search" @emptyFilter="getEarningDetail" 
            @filtered="filterHandler" :key="Reloadfilter" :useCache="true"
            @updateCache="updateCache" class="mx-3" :oneDateFilter="true"
            :columnfilterEndpoint="$API.inventoryReports.getInventoryFilterReportColumn">
                <DxDataGrid class="isScrolledByGeneralFilter" :data-source="reportsData" :show-borders="true"
                    @exporting="onExporting" @selectionChanged="selectionChanged" :scrolling="{ useNative: true }"
                    :column-auto-width="true" :paging="{ enabled: false }" style="min-height: 600px; max-height:600px">
                    <DxExport :enabled="true" />
                    <DxColumn cell-template="seeDetail" />
                    <DxColumn data-field="ID_Item" caption="ID Producto" />
                    <DxColumn data-field="PrincipalBarCode" caption="Código Alterno Principal" />
                    <DxColumn data-field="ItemName" caption="Nombre Producto" />
                    <DxColumn data-field="Lote" caption="Lote" />
                    <DxColumn v-if="$store.getters['rolesUser/getArrayCustomRole']([242])" data-field="LastPurchaseDate" caption="Última Fecha de compra" />
                    <DxColumn v-if="$store.getters['rolesUser/getArrayCustomRole']([242])" data-field="LastPurchasePrice" caption="Último Costo de compra" />
                    <DxColumn v-if="$store.getters['rolesUser/getArrayCustomRole']([242])" data-field="AverageCostFormatted" caption="Costo promedio" />
                    <DxColumn data-field="Providers" caption="Proveedores" />
                    <DxColumn data-field="WarehouseName" caption="Almacén" />
                    <DxColumn data-field="ItemMeasureUnit" caption="Unidad" />
                    <DxColumn data-field="ItemMinimum" caption="Minimo" />
                    <DxColumn data-field="ItemMaximum" caption="Maximo" />
                    <DxColumn data-field="TotalStock" :width="100" caption="Disponible" />

                    <template #seeDetail="{ data }">
                        <v-btn class="btn-add" href="#" @click.prevent.stop="
                            selectedReport = data.data; form.warehouse = data.data.ID_Warehouse; form.add =true;
                        showReport = true;
                        "><v-icon color="white" class="mr-2">mdi-package-variant-closed</v-icon>Hacer Ajuste
                        </v-btn>
                    </template>

                    <DxScrolling mode="virtual"/>
                    <DxLoadPanel :enabled="true"/>
                </DxDataGrid>
        </VersatileFilter>

        <v-dialog v-model="showReport" max-width="80%" scrollable>
            <v-card class="border" style="max-height: inherit;overflow-y: scroll;" >
                <v-btn @click="showReport= false" elevation="0" color="red" class="close-btn align-self-end"><v-icon>mdi-close</v-icon></v-btn>
                <div v-if="selectedReport" class="pa-10">
                    <v-row>
                        <v-col cols="12" md="4">
                            <date-picker label="Fecha Ajuste" @dateSelected="changueDt" v-model="form.date"
                                :value="form.date" /> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h3>Detalle de Producto</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>ID Producto: </strong>
                                {{ selectedReport.ID_Item }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Nombre: </strong>
                                {{ selectedReport.ItemName }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Almacén: </strong>
                                {{ selectedItemWarehouseName }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Unidad: </strong>
                                {{ selectedReport.ItemMeasureUnit }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Lote: </strong>
                                {{ selectedReport.Lote ? selectedReport.Lote : "Por defecto" }}
                            </span>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <span>
                                <strong>Último precio ingresado: </strong>
                                {{
                                    selectedReport.LastPrice
                                }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h3>Parametros de ingreso</h3>
                        </v-col>
                        <v-col cols="12" md="4">
                          
                            <v-radio-group  v-model="form.add" row>
                                <v-radio label="Agregar existencia" :value="true"></v-radio>
                                <v-radio label="Restar existencia" :value="false"></v-radio>                           
                            </v-radio-group>
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <v-text-field v-model="form.quantity" label="Cantidad" outlined dense type="number" />
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <v-text-field v-model="getsetAverageCost" label="Costo unitario de Compra"  outlined dense />
                            
                           
                        </v-col>

                    </v-row>
                    <v-row>
                       
                       
                        <v-col col="12" md="4" xl="4">
                            <v-text-field v-model="getsetLote" label="Lote" :value="this.selectedReport.Lote"  outlined dense />
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <v-select v-model="getsetWarehouse" label="Almacén"
                                :items="datafilter.Warehouses" item-text="name" item-value="value"  attach
                            outlined dense />
                        </v-col>
                        <v-col col="12" md="4" xl="4">
                            <v-btn @click="showComments = !showComments" :class="form.comment ? 'btnclean' : 'btnblack'">
                                Comentarios
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <h2>
                                {{ "Existencias Actuales: " + stockToShow }}
                            </h2>
                        </v-col>
                        <v-col cols="12" md="6">
                            <h2>
                                {{ "Existencias Nuevas: " + totalStock }}
                            </h2>
                        </v-col>
                    </v-row>
                </div>
                <v-card-actions class="mt-3">
                    <v-btn @click="showReport = false" class="btndelete">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="btnsave" :disabled="!form.quantity || !form.date" @click="
                        showReport = false;
                    sendAdjustment();
                    ">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showComments" max-width="80%">
            <v-card>
                <div style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: flex-end;
          ">
                    <v-btn small class="btndelete" @click.prevent="showComments = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-col cols="12" md="12" style="">
                    <quill-editor v-model="form.comment"></quill-editor>
                </v-col>
            </v-card>
        </v-dialog>

        <!--   <v-dialog
        v-model="showFilter"
        max-width="80%"
        
       >
        <v-card>
            <v-card-title>
                <h3>Filtros</h3>
            </v-card-title>
            <v-card-text>
                <v-row class="mt-2">
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="filterParams.ID_Item"
                            label="ID Producto"
                            outlined dense
                            />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="filterParams.ItemName"
                            label="Nombre Producto"
                            outlined dense
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="filterParams.ItemLote"
                            label="Lote"
                            outlined dense
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="filterParams.providersNames"
                            label="Proveedores"
                            outlined dense
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="filterParams.WarehouseName"
                            label="Almacen"
                            outlined dense
                        />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions >
                <v-btn @click="
                    showFilter = false;
                    cleanFilter();" >Limpiar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                 @click="
                 showFilter = false;
                 getFilterEarningDetail()" >Filtrar</v-btn>
            </v-card-actions>

        </v-card>
       </v-dialog> -->
        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
    </v-card>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxExport,
    DxScrolling,
    DxLoadPanel
} from "devextreme-vue/data-grid";
import { printToExcel } from "@/helpers/printToexcel";
import VersatileFilter from "@/components/VersatileFilter";
//import GeneralFilter from  "@/components/GeneralFilter";
import Block from "@/components/Block";
import DatePicker from "@/components/DatePicker";
import Alerts from "@/components/Alerts";

export default {
    name: "InventoryPhysicalR",
    components: {
        Block,
        DxDataGrid,
        DxColumn,
        DxExport,
        //  GeneralFilter,
        // DxSelection,
        DatePicker,
        Alerts,
        VersatileFilter,
        DxScrolling,
        DxLoadPanel
    },
    props: ["win", "datafilter"],
    data() {
        return {
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            loading: false,
            panel: 0,
            reportsData: [],
            start: null,
            end: null,
            summary: {
                totalUnitSales: 0,
                totalCostSales: 0,
            },
            search: {},
            showFilter: false,
            filterParams: {
                ID_Item: null,
                ItemName: null,
                ItemLote: null,
                providersNames: null,
                WarehouseName: null,
                add: null,
            },
            Reloadfilter: 0,
            columsConfig: [
                {
                    name: "ID_Warehouse",
                    title: "Almacenes",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "family",
                    title: "Familia",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "category",
                    title: "Categoría",
                    value: "",
                    parent: 'family',
                    type: "select",
                    options: [],
                },
                {
                    name: "subcategory",
                    title: "Subcategoría",
                    value: "",
                    parent: 'category',
                    type: "select",
                    options: [],
                },
                {
                    name: "Lote",
                    title: "Lote",
                    value: "",
                    type: "select",
                    options: [],
                },
            ],
            cacheData: [],
            selectedReport: null,
            form: {},
            showComments: false,
            showReport: false,
            warehouses: [],
           
        };
    },
    computed: {
        selectedItemWarehouseName() {
            if ( this.form.warehouse && !this.selectedReport.WarehouseName ) {
                // console.log( 'this.form.warehouse', this.form.warehouse, this.selectedReport.WarehouseName )
                return this.datafilter.Warehouses.find( current => {
                    const { value: ID_Warehouse } = current;
                    return ID_Warehouse === this.form.warehouse;
                } ).name;
            } else {
                return this.selectedReport.WarehouseName
            } 
        },
        totalStock() {
            if (this.selectedReport.TotalStock && this.form.quantity && !this.form.add) {
                return this.selectedReport.TotalStock - this.form.quantity;
            } else if (this.selectedReport.TotalStock && this.form.quantity && this.form.add) {
                return this.selectedReport.TotalStock + +this.form.quantity;
            } else if (this.selectedReport.TotalStock) {
                return this.selectedReport.TotalStock;
            }
            else if((this.selectedReport.TotalStock == null || this.selectedReport.TotalStock == undefined || this.selectedReport.TotalStock == 0 ) && this.form.quantity  ) 
            {
                return this.form.add ? this.form.quantity : -this.form.quantity;
            } 
            return 0;
        },
        getsetLote: {
            get () {        
            
                return this.selectedReport.Lote
            
            },
            set (val) {
                // alert(val)
                if (val=="") {
                this.form.lote =  this.selectedReport.Lote
                } else {
                this.form.lote = val
                }
                
            }
        },
        getsetAverageCost: {
        get () {
        
            return this.selectedReport.AverageCost
        
        },
        set (val) {
        
        
            this.form.price = val
            
        }
        },
        getsetWarehouse: {
          
          get () {        
           
              return this.selectedReport.Warehouse
           
          },
          set (val) {
            // alert(val)
            if (val=="") {
              this.form.warehouse = this.selectedReport.Lote
            } else {
              this.form.warehouse = val
            }
            
          }
        },
        stockToShow() {
            return this.selectedReport.TotalStock ? this.selectedReport.TotalStock : 0;
        },
    },
    
    methods: {
        verifyR() {
            if (!this.selectedReport) {
                this.showAlert("warning", "Ajuste de inventario", "Seleccione un producto");
                return;
            }
            this.showReport = true;
        },
        closeAlert() {
            this.alert.show = false;

            if (this.alert.options == "reload") {
                this.getEarningDetail();
            }
            else if (this.alert.options == "reloadwithalert") {
                console.log('recargando');
                this.reloadDatawithParams();
               
            }
        },
        acceptAlert() {
            this.alert.show = false;
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        reloadDatawithParams(){
          
            this.cacheData.fromAdjustment = true;
            this.$API.inventoryReports.getInventoryFilterReportColumn(this.cacheData).then(response => {
                this.reportsData = response;
            })
        },
        sendAdjustment() {
            if (!this.form.warehouse) {
          this.form.warehouse =  this.selectedReport.Warehouse
        } 
            if(this.form.warehouse == null){
                return this.showAlert(
                    "warning",
                    "Ajuste de inventario",
                    "Seleccione un almacén"
                );
            }
            if(this.totalStock < 0){
                return this.showAlert(
                    "warning",
                    "Ajuste de inventario",
                    "No se puede realizar un ajuste negativo"
                );
            }
            this.loading = true;
            if (!this.form.lote) {
          this.form.lote =  this.selectedReport.Lote
        } 
        if (!this.form.price) {
          this.form.price =  this.selectedReport.AverageCost
        } 
        //alert(this.form.lote);
            this.$API.inventoryReports
                .sendAdjusmten({
                    item: this.selectedReport,
                    adjustmentParams: this.form,
                })
                .then((response) => {
                    this.loading = false;
                    this.showAlert(
                        "success",
                        "Ajuste de inventario",
                        "Ajuste realizado correctamente",
                        "reloadwithalert"
                    );
                    console.log(response);
                    this.form = {};
                    this.selectedReport = null;
                });
        },
        updateCache(data) {
            this.cacheData = data;
        },
        selectionChanged(e) {
            var selectedRows = e.selectedRowsData;
            if (selectedRows.length > 0) {
                this.selectedReport = selectedRows[0];
            }
        },

        getEarningDetail() {
            
            
            this.loading = true;
            this.$API.inventoryReports
                .getInventoryReport()
                .then((response) => {
                    this.loading = false;
                    this.reportsData = response;
                    this.selectedReport = null;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        onExporting(e) {
            printToExcel(e, "Reporte de Existencias (Informe Físico)");
        },
        filterHandler(response) {
            this.reportsData = response;
        },
        cleanFilter() {
            this.filterParams = {
                ID_Item: null,
                ItemName: null,
                ItemLote: null,
                providersNames: null,
                WarehouseName: null,
            };
            this.getEarningDetail();
        },
        getFilterEarningDetail() {
            this.loading = true;
            this.$API.inventoryReports
                .getInventoryFilterReport(this.filterParams)
                .then((response) => {
                    this.loading = false;
                    this.reportsData = response;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        changueDt(date) {
            this.form.date = date;
        },
        assignParams() {
            if (this.datafilter) {
                this.columsConfig[0].options = this.datafilter.Warehouses;
                this.columsConfig[1].options = this.datafilter.Families;
                this.columsConfig[2].options = this.datafilter.Categories;
                this.columsConfig[3].options = this.datafilter.SubCategories;
                this.columsConfig[4].options = this.datafilter.batches;
                this.Reloadfilter += 1;
            }
        },
       
    },
    mounted() {
        this.assignParams()
      
    },
};
</script>

<style scoped>
.btnblack {
    background-color: rgba(60, 60, 60, 1) !important;
    color: white !important;
}

.close-btn {
    color: white !important;
    position: relative !important;
    float: right !important;
}

.border {
  border-radius: 15px !important;
}
</style>
